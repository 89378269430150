import api from "@/api/api";
import request from "@/utils/request";
import { FetchCodeListType } from "@/pages/CadreAllTogether/interface/type";

export const logout = (): Promise<API.BaseResponse> => {
  return request.post(api.system_logout, {});
};

export const getFetchCodes = (data: API.CodeListReq): Promise<FetchCodeListType> => {
  return request.post(api.code_fetchCodes, data);
};

export const getFetchCountry = (data?: API.CountryListReq): Promise<API.CountryListRes> => {
  return request.post(api.user_fetchCountry, data);
};

// 开发商一览列表
export const fetchDeveloperList = (data: API.DevelopMasterDataReq): Promise<API.DevelopMasterDataRes> => {
  return request.post(api.developer_fetchDeveloperList, data);
};
