import api from "@/api/api";
import { logout } from "@/api/common";
import { LOGINOUT_TIME, DEFAULT_LANGUAGE } from "@/utils/config";
import { clearAuthInfo, getLanguageTypeInfo, getToken, setLanguageTypeInfo } from "@/utils/utils";
import { message } from "antd";
import i18n from "./Language/i18n";

let timeOut: any = undefined;

const isLogout = async () => {
  try {
    if (timeOut) {
      clearTimeout(timeOut);
      timeOut = undefined;
    }
    timeOut = setTimeout(async () => {
      // 提示信息
      message.error(i18n.t("Common_autoLogout_tip"));
      if (window.location.pathname !== "/user/login") {
        const res = await logout();
        if (Number(res?.code) > 0) {
          clearAuthInfo();
          window.location.replace(window.location.href);
        }
      }
    }, LOGINOUT_TIME);
  } catch (error) {
    console.log(error);
  }
};
isLogout();
document.addEventListener("mousemove", isLogout);
document.addEventListener("click", isLogout);
document.addEventListener("keydown", isLogout);
/* window.addEventListener("unload", () => {
  const token = getToken();
  if (token) {
    fetch("/api" + api.system_logout, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
      keepalive: true,
    })
      .then(() => {})
      .catch(() => {});
  }
}); */
if (!getLanguageTypeInfo()) {
  setLanguageTypeInfo(DEFAULT_LANGUAGE);
}