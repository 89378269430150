import { getLanguageTypeInfo } from "@/utils/utils";
import { DEFAULT_LANGUAGE } from "@/utils/config";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_US from "./en_US.json";
import ja_JP from "./ja_JP.json";

const languageInfo = getLanguageTypeInfo() ?? DEFAULT_LANGUAGE;

i18n.use(initReactI18next).init({
  resources: {
    en_US: {
      translation: en_US,
    },
    ja_JP: {
      translation: ja_JP,
    },
  },
  lng: languageInfo,
  fallbackLng: languageInfo,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
