import { atom } from "recoil";

/* guest 自动登录  normal 手动登录  */ type loginMode = "guest" | "guest" | "normal";

export type UserInfoType = {
  id: number;
  staffCD: string;
  countryId: number;
  username: string | null;
  loginType: loginMode;
  password: string | null;
  oldPassword: string | null;
  confirmPassword: string | null;
  partCD: string;
  partNM: string;
  mailAddress: string;
  countryCD: string;
  countryNm: string;
  departmentCD: string;
  departmentName: string;
  userRoleId: number;
  userRoleName: string;
  userRoleNameEng: string;
  searchRange: string;
  userRoleCd: string;
  userjpnm: string | null;
  userknnm: string | null;
  userjpFirst: string | null;
  userjpLast: string | null;
  syskbn: boolean;
  updateUserCd: string;
  updateUserNm: string;
  updateTime: string;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
};

/**
 * 当前用户可查看的路由权限数组
 * @return { UserInfoType }
 * @see  {@link https://www.recoiljs.cn/docs/basic-tutorial/atoms }
 */
export const userInfo = atom<UserInfoType>({
  key: "userInfo",
  default: {} as UserInfoType,
});
