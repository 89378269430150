import SecurityLayout from "@/Layout/SecurityLayout";
import UniversalLayout from "@/Layout/UniversalLayoutRoutes/index";
import UniversalLayoutRoutes from "@/Layout/UniversalLayoutRoutes/router";
import UserLayout from "@/Layout/UserNotLoginLayout/index";
import UserLayoutRoutes from "@/Layout/UserNotLoginLayout/router";
import PageLoading from "@/components/PageLoading";
import { menuLayout } from "@/store/menuLayout";
import { createUseRoutes, pathKeyCreateUseRoutes, lazyWithErrorHandle } from "@/utils/utils";
import { Suspense, memo } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import NotFoundPage from "../Layout/NouFoundLayout/BlankLayout";

type Meta = {
  title: string;
  roles: string[];
  icon?: React.ReactNode;
};

type RouterProps = {
  path: string;
  meta?: Meta;
  redirect?: string;
  component?: React.FC;
  children?: RouterProps[];
};

const routes = createUseRoutes([
  {
    path: "/",
    redirect: "/overseasShellSpeedShop",
    children: UniversalLayoutRoutes,
  },
  {
    path: "/user",
    // redirect: "/user/login",
    children: UserLayoutRoutes,
  },
  {
    path: "*",
    component: lazyWithErrorHandle(() => import("@/pages/NotFound/index")),
  },
]);

const SuspenseLazy = memo(({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<PageLoading />}>{children}</Suspense>
));

/**
 * 配置框架对应的路由
 */
const layoutToRoutes = {
  UniversalLayout: pathKeyCreateUseRoutes([routes[0]]),
  UserLayout: pathKeyCreateUseRoutes([routes[1]]),
};

export default () => {
  const location = useLocation();
  const routesElement = useRoutes(routes);
  const menuLayoutInfo = useRecoilValue(menuLayout);

  // 已登录页面布局征程
  if (layoutToRoutes.UniversalLayout[location.pathname]) {
    return (
      <SecurityLayout>
        <UniversalLayout menuLocation={menuLayoutInfo}>
          <SuspenseLazy>{routesElement}</SuspenseLazy>
        </UniversalLayout>
      </SecurityLayout>
    );
  }

  // 未登录页面布局征程
  if (layoutToRoutes.UserLayout[location.pathname]) {
    return (
      <UserLayout>
        <SuspenseLazy>{routesElement}</SuspenseLazy>
      </UserLayout>
    );
  }

  return (
    <NotFoundPage>
      <SuspenseLazy>{routesElement}</SuspenseLazy>
    </NotFoundPage>
  );
};
export { routes };
export type { RouterProps };
