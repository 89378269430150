import api from "@/api/api";
import request from "@/utils/request";

export const userLogin = (params: API.LoginReq): Promise<API.LoginRes> => {
  return request.post(api.system_login, params);
};

export const loginEdit = (params: API.LoginReq): Promise<API.LoginRes> => {
  return request.post(api.system_loginEdit, params);
};

export const forgetPasswordInterface = (data: API.ForgetPasswordReq): Promise<API.ForgetPasswordRes> => {
  return request.post(api.system_forgetPassword, data);
};

export const sendVerifyEmail = (data: API.SendVerifyEmailReq): Promise<API.SendVerifyEmailRes> => {
  return request.post(api.system_sendVerifyEmail, data);
};

export const emailLogin = (data: API.EmailLoginReq): Promise<API.EmailLoginRes> => {
  return request.post(api.system_emailLogin, data);
};

export const fetchGoogleLoginRedirectUrl = (): Promise<API.GoogleLoginRedirectUrlRes> => {
  return request.post(api.system_getRedirectUrl, {});
};

export const findTenantId = (data: { staffCD: string }): Promise<API.FindTenantIdRes> => {
  return request.post(api.user_findTenantId, data);
};

export const saveLog = (data: { mailAddress: string; info: string }): Promise<API.BaseResponse> => {
  return request.post(api.api_system_saveLog, data);
};
