import { SelectorType, TreeSelectType } from "@/@types/publicType";
import { JurisdictionRouterAuthority, JurisdictionRouterAuthorityPage } from "@/pages/RoleViewScreen/interface/hook";
import { TreeDataNode } from "antd";
import { merge } from "antd/es/theme/util/statistic";
import dayjs, { Dayjs } from "dayjs";
import { createElement, lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import {
  OVERSEAS_DIRECT_STOTE,
  OVERSEAS_LOGIN,
  OVERSEAS_SHOP_NUMBER,
  STORAGEKEY_LANGUAGE,
  STORAGEKEY_TOKEN,
  STORAGEKEY_USERINFO,
  STORAGEKEY_GOOGLECODE,
} from "./config";

type IPathKeyRouteObject = {
  [path: string]: RouteObject;
};

type PaginationHeaders = {
  headers: {
    "x-pagination-index": number;
    "x-pagination-size": number;
  };
};

export const detectDevice = () => {
  const userAgent = navigator.userAgent;
  if (/Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
    return "mobile";
  }
  return "desktop";
};

export const formatNumber = (str: string): string => {
  const addCommas = (num: string) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (/^\d+$/.test(str)) {
    return addCommas(str);
  }

  return str;
};

/**
 * 判断当前用户需要跳转到哪个页面
 * @param  { string }  userRoleCd 当前角色CD
 * @return { void }
 */
export const ifUserGoRouter = (userRoleCd: string, flag = false): void => {
  let goRouter: string;
  if (detectDevice() === "mobile") {
    location.replace("/overseasSalesSpeed/overseasSalesSpeed-totalSalesSpeed");
    return;
  }
  switch (userRoleCd) {
    case "FANTASY_ABROAD":
      goRouter = OVERSEAS_SHOP_NUMBER;
      break;
    case "FANTASY_JP":
      goRouter = OVERSEAS_SHOP_NUMBER;
      break;
    case "FANTASY_JP_LOGIN":
      goRouter = OVERSEAS_SHOP_NUMBER;
      break;
    default:
      goRouter = OVERSEAS_SHOP_NUMBER;
      break;
  }

  const timeId = setTimeout(
    () => {
      location.replace(goRouter);
      clearTimeout(timeId);
    },
    flag ? 0 : 1000
  );
};

/**
 * 控制键盘按键可输入的范围
 * @param  { React.KeyboardEvent<HTMLInputElement> }  e
 */
export const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const { key } = e;

  // 全角数字字符的 Unicode 范围是 65296-65305
  const isFullWidthDigit = key >= "０" && key <= "９";
  // 半角数字字符的 Unicode 范围是 48-57
  const isHalfWidthDigit = key >= "0" && key <= "9";

  // 禁止输入非数字字符
  if (
    !isHalfWidthDigit && // 半角数字
    !isFullWidthDigit && // 全角数字
    key !== "Backspace" && // 退格键
    key !== "Delete" && // 删除键
    key !== "ArrowLeft" && // 左箭头
    key !== "ArrowRight" // 右箭头
  ) {
    e.preventDefault();
  }
};

/**
 * 生成用于页面请求的头部信息
 * @param { number }  pageIndex 当前页码
 * @param { number }  pageSize 每页显示的数量
 * @return { PaginationHeaders }
 */
export const pageHeader = (pageIndex: number, pageSize: number): PaginationHeaders => {
  return {
    headers: {
      "x-pagination-index": pageIndex,
      "x-pagination-size": pageSize,
    },
  };
};

export const ifRouterRedirect = (type: string) => {
  if (type === "overseas") {
    if (JSON.parse(sessionStorage.getItem("userInfo")!)?.userRoleCd === "FANTASY_ABROAD") {
      return OVERSEAS_DIRECT_STOTE;
    }

    if (JSON.parse(sessionStorage.getItem("userInfo")!)?.userRoleCd === "FANTASY_JP") {
      return OVERSEAS_SHOP_NUMBER;
    }

    if (JSON.parse(sessionStorage.getItem("userInfo")!)?.userRoleCd === "FANTASY_JP_LOGIN") {
      return OVERSEAS_DIRECT_STOTE;
    }

    return OVERSEAS_DIRECT_STOTE;
  }
  if (type === "jurisdiction") {
    if (JSON.parse(sessionStorage.getItem("userInfo")!)?.userRoleCd === "FANTASY_ABROAD") {
      return "jurisdiction-developMaster";
    }

    if (JSON.parse(sessionStorage.getItem("userInfo")!)?.userRoleCd === "FANTASY_JP") {
      return "jurisdiction-userViewScreen";
    }

    if (JSON.parse(sessionStorage.getItem("userInfo")!)?.userRoleCd === "FANTASY_JP_LOGIN") {
      return "jurisdiction-cadreAllTogether";
    }

    return "jurisdiction-developMaster";
  }
};

/**
 * 转换 TreeSelect 组件所需数据
 * @param  { T[] }       arrayList 需要转换的数据格式
 * @param  { keyof T }   title    对应 title 名称
 * @param  { keyof T }   valueKey 对应的 value 和 key
 * @return { TreeSelectType[] }
 */
export function converTreeSelectData<T>(arrayList: T[], title: keyof T, valueKey: keyof T): TreeSelectType[] {
  return arrayList.map((item) => {
    return {
      title: item[title] as unknown as string,
      value: item[valueKey] + "",
      key: item[valueKey] + "",
    };
  });
}

/**
 * 将数据转换成 selector 组件可识别的代码结构
 * @param  { T[] }       arrayList 数据数据
 * @param { keyof T }    value 需要转换成 value 的字段
 * @param { keyof T }    label 需要转换成 label 的字段
 * @return { SelectorType[] }
 */
export function convertDataToSelector<T>(arrayList: T[], value: keyof T, label: keyof T): SelectorType[] {
  return arrayList.map((item) => {
    return {
      label: item[label] + "",
      value: item[value] + "",
    };
  });
}

/**
 * 循环获取嵌套结构的某个字段以数组的形式返回
 * @param  { [ any ] }   arrayList    嵌套结构的原数据
 * @param  { string }    key          需要循环遍历的嵌套结构字段 "key" or other attribute？
 * @param  { string }    children     嵌套结构数组内部的字段名称 "children" or other attribute？
 * @return { string[] }
 * @example {  flatMapCircuitToGetAField(array,"you needed "key"","you needed "children"")  }
 */
export function flatMapCircuitToGetAField<T>(arrayList: T[], key: keyof T, children: keyof T): string[] {
  return arrayList.flatMap((item: any) => {
    let newList = [item[key]];
    if (!!item[children] && item[children].length) {
      newList = [...newList, ...flatMapCircuitToGetAField(item[children], key, children)];
    }
    return newList;
  });
}

/**
 * code状态码码映射
 */
const codeArray: Record<string, boolean> = {
  "101": true,
  "102": true,
  "-104": false,
  "-114": false,
  "-170": false,
};
/**
 * 判断接口状态 返回布尔值
 * @param  { string }  codeStatus 接口返回 code 值
 * @return { boolean }
 */
export const isInterfaceSuccess = (codeStatus: string): boolean => {
  if (codeArray[codeStatus] !== void 996) {
    return codeArray[codeStatus];
  } else {
    console.error("调用了判断接口返回值的 isInterfaceSuccess 函数，但是传入的参数在 codeArray 枚举对象中不存在");
    return false;
  }
};

/**
 * 获取会话语言类型
 * @return { string }
 */
export const getLanguageTypeInfo = (): string | null => sessionStorage.getItem(STORAGEKEY_LANGUAGE);
export const setLanguageTypeInfo = (lang: string) => sessionStorage.setItem(STORAGEKEY_LANGUAGE, lang);

/**
 * 获取会话Token
 * @return { string }
 */
export const getToken = (): string | null => sessionStorage.getItem(STORAGEKEY_TOKEN);
export const setToken = (token: string) => sessionStorage.setItem(STORAGEKEY_TOKEN, token);
export const removeToken = () => sessionStorage.removeItem(STORAGEKEY_TOKEN);

//
export const getGoogleCode = (): string | null => sessionStorage.getItem(STORAGEKEY_GOOGLECODE);
export const setGoogleCode = (code: string) => sessionStorage.setItem(STORAGEKEY_GOOGLECODE, code);
export const removeGoogleCode = () => sessionStorage.removeItem(STORAGEKEY_GOOGLECODE);

export const getUserInfo = (): API.UserInfo | null => {
  const userInfo = sessionStorage.getItem(STORAGEKEY_USERINFO);
  if (userInfo) {
    return JSON.parse(userInfo);
  } else {
    return null;
  }
};
export const setUserInfo = (userInfo: API.UserInfo) => sessionStorage.setItem(STORAGEKEY_USERINFO, userInfo ? JSON.stringify(userInfo) : "");

export const clearAuthInfo = () => {
  sessionStorage.removeItem(STORAGEKEY_USERINFO);
  sessionStorage.removeItem(STORAGEKEY_TOKEN);
};
/**
 * 组件库 ( select ) 下拉组件可搜索
 * @param { string }        input  搜索内容
 * @param { SelectorType }  option 下拉框数据值
 * @return { boolean }
 */
export const filterOption = (input: string, option?: { label: string; value: string | number }): boolean =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

/**
 * 导出文件类型
 */
export const fileTypeMapping: Record<string, string> = {
  pdf: "application/pdf",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.ms-excel",
};

/**
 * @param { any }      data        二进制文件流
 * @param { string }   excelName   导出的文件名字
 * @param { string }   exportType  导出文件的类型
 * @return { void }
 */
export const ExportFile = (data: any, excelName: string, exportType: string): void => {
  const blob = new Blob([data], {
    type: exportType,
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${excelName}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * 查询 value 对应的 lable
 * @param { T[] }       data   数组
 * @param { string }    value  需要在 data 参数中查找的具体 label
 * @return { string | void}
 */
export function findLabel<T extends SelectorType>(data: T[], value: keyof T | string): string {
  return data?.find((item) => item.value === value)?.label ?? " ";
}

/**
 * 将原 Menu 添加 key、title 属性
 * @param  { JurisdictionRouterAuthorityPage }  menuData 接口原始数据
 * @return { JurisdictionRouterAuthorityPage }  返回添加了 key title 属性的菜单
 * @description  子级拼接父级别的 pageId ，按钮 key 拼接父级的新属性 key
 */
export const addTitleAndKeyToMenu = (menuData: any): any => {
  menuData.forEach((oneItem: any) => {
    oneItem["key"] = oneItem.menuId + "";
    oneItem["title"] = oneItem.menuName;
    if (oneItem.children && oneItem.children.length) {
      oneItem.children.forEach((twoItem: any) => {
        twoItem["key"] = `${oneItem["key"]}-${twoItem.menuId}`;
        twoItem["title"] = twoItem.menuName;
        if (twoItem.authority && twoItem.authority.length) {
          twoItem.authority.forEach((threeItem: JurisdictionRouterAuthority) => {
            threeItem["key"] = `${twoItem["key"]}-${threeItem.id}`;
            threeItem["title"] = threeItem.name;
          });
          twoItem.children = twoItem.authority;
        }
      });
    }
  });
  return menuData;
};

/**
 * 从添加 key title 属性的新 Menu 数据中 => 过滤 Tree 组件需要的 key title children
 * @param  { JurisdictionRouterAuthorityPage }  newMenu 添加了 key title 属性的 Menu 数据
 * @return { TreeDataNode[] }                   Tree 组件需要的数据
 * @description   为了选中 Tree 拿到 key 之后 在新的 Menu 数据中过滤选中的菜单
 */
export const convertMenuToTreeComponent = (newMenu: JurisdictionRouterAuthorityPage): TreeDataNode[] => {
  return newMenu.map((item) => {
    const newItem: TreeDataNode = {
      key: item.key ?? "",
      title: item.title,
    };

    if (item.children && item.children.length > 0) {
      newItem.children = convertMenuToTreeComponent(item.children);
    }

    return newItem;
  });
};

/**
 * 根据 tree 的 key 找到对应的菜单数据
 * @param  { JurisdictionRouterAuthorityPage }  data  添加过 key title 的 menu 数据
 * @return { JurisdictionRouterAuthorityPage }  过滤之后的 menu 数据
 */
export const filterCheckedData = (data: JurisdictionRouterAuthorityPage, keys: React.Key[]): JurisdictionRouterAuthorityPage => {
  return data
    ?.map((item) => {
      let filterObject = (item.children || []).filter((auth) => keys.includes(auth.key));
      return filterObject.length > 0
        ? {
            ...item,
            children: filterObject,
            authority: filterObject,
          }
        : {
            ...item,
            children: filterCheckedData(item.children || [], keys),
            authority: filterCheckedData(item.children || [], keys),
          };
    })
    .filter((item) => item.authority.length > 0);
};

/**
 * @文件上传可以上传的文件格式校验
 * @param { string }        filePostfix      文件后缀名字   例如：xls
 * @param { Array<string> } filePostfixList  可以进行上传的文件   例如：["xlsx", "xls", "pdf"]
 * @return { boolean }
 */
export const fileUploadVerify = (filePostfix: string, filePostfixList: Array<string>): boolean => filePostfixList.includes(filePostfix);

/**
 * 对象值为 undefined 自动转换为空字符串
 * @param {  Record<string, any> }  object  需要转换值为undefined 为 "" 的对象
 * @return {  Record<string, any> }
 */
export const conver_Obj_Undefined_As_String = (object: Record<string, any>): Record<string, any> => {
  for (const key in object) {
    if (object[key] === undefined) {
      object[key] = "";
    }
  }
  return object;
};

/**
 * 判断有无日期并且转换
 * @param { Dayjs }  date  日期字段
 * @returns { Dayjs | ""}
 */
export const convert_Date = (date: Dayjs): Dayjs | string => (date ? dayjs(date) : "");

/**
 * createUseRoutes处理后的数据转成 IPathKeyRouteObject 格式
 * @param { RouteObject[] }  routes  RouteObject[] 经过 createUseRoutes 处理后的routes
 * @returns { IPathKeyRouteObject }
 */
export const pathKeyCreateUseRoutes = (routes: RouteObject[]): IPathKeyRouteObject => {
  let jsonItems: IPathKeyRouteObject = {};
  for (let index = 0; index < routes.length; index++) {
    const item = routes[index];
    jsonItems[item.path || ""] = {
      ...item,
    };

    if (item.children) {
      jsonItems = merge(jsonItems, pathKeyCreateUseRoutes(item.children));
    }
  }
  return jsonItems;
};

const isExternal = (path: string): boolean => /^(https?:|mailto:|tel:)/.test(path);

/**
 * 根据 configRoutes: IRouter[] 生成 useRoutes 的参数 routes: RouteObject[] 的数据
 * @param { any }  configRoutes  IRouter[] config配置的路由
 * @param { "/" }  parentPath    string 上级path
 * @return { RouteObject[] }
 */
export const createUseRoutes = (configRoutes: any, parentPath = "/"): RouteObject[] => {
  const routes: RouteObject[] = [];
  for (let index = 0; index < configRoutes.length; index++) {
    const item = configRoutes[index];
    if (isExternal(item.path)) {
      continue;
    }
    const routesItem: RouteObject = {};

    // path
    routesItem.path = item.path.startsWith("/") ? item.path : `${parentPath.endsWith("/") ? parentPath : `${parentPath}/`}${item.path}`;
    // element
    if (item.component) {
      routesItem.element = createElement(item.component);
    }
    // children
    const children: RouteObject[] = [];
    // 检测路由重定向
    if (item.redirect) {
      children.push({
        path: routesItem.path,
        element: createElement(Navigate, { to: item.redirect }),
      });
    }
    if (item.children) {
      children.push(...createUseRoutes(item.children, routesItem.path));
    }
    if (children.length > 0) {
      routesItem.children = children;
    }

    // newItem push
    routes.push(routesItem);
  }

  return routes;
};

/**
 * FormData Value 格式化
 * @param value
 * @returns
 */
export const getFormDataValue = (value: unknown): string => {
  if (value === null || value === undefined) {
    return "";
  }
  if (typeof value === "object") {
    try {
      return JSON.stringify(value);
    } catch {
      return value.toString();
    }
  }
  return String(value);
};

export const convertType = (v: any, minus?: any) => {
  let result = "";
  const str = v + "";
  const len = str.length;
  const reg = /^[-ー]?\d+$/; // 负号只能在首位
  for (let i = 0; i < len; i++) {
    let cCode = str.charCodeAt(i);
    cCode = cCode >= 0xff01 && cCode <= 0xff5e ? cCode - 65248 : cCode;
    cCode = cCode === 0x03000 ? 0x0020 : cCode;
    // 如果输入全角负号，转换为半角
    if (minus && cCode === 12540) {
      cCode = 45;
    }
    result += String.fromCharCode(cCode);
  }
  if (minus && !reg.test(result)) {
    result = "";
  }
  return result;
};

// 手写防抖 (单位时间内函数只会执行最后一次)
export const debounce = (callback: Function, wait: number) => {
  let timeoutId: any;
  return (...args: any) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      callback.apply(this, args);
    }, wait);
  };
};
// 手写节流 (单位时间内函数只会执行第一次)
export const throttle = (callback: Function, delay: number) => {
  let lastExecution = 0;
  return (...args: any) => {
    const now = Date.now();
    if (now - lastExecution >= delay) {
      callback.apply(this, args);
      lastExecution = now;
    }
  };
};
export const generateUniqueKey = (length = 8) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const lazyWithErrorHandle: typeof lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch {
      window.location.replace(OVERSEAS_LOGIN);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      throw new Error("ページが更新されまため、リロードしています...");
    }
  };

  return lazy(retryImport);
};
// 数值转字符串(规避科学计数法表达式)
export const toPlainString = (num: any) => {
  let str = typeof num === "number" ? num.toString() : isNaN(Number(num)) ? "" : num;
  if (str && (str.indexOf("e") !== -1 || str.indexOf("E") !== -1)) {
    str = num.toFixed(20).replace(/\.?0+$/, "");
  }
  return str;
};

export const rightSvg = (
  <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10386" width="16">
    <path
      d="M510.44304 940.315042c-237.326197 0-430.396447-193.21249-430.396447-430.695253 0-237.502206 193.07025-430.717765 430.396447-430.717765 237.303684 0 430.40668 193.236026 430.40668 430.717765C940.84972 747.101529 747.791749 940.315042 510.44304 940.315042zM510.44304 124.414591c-212.213239 0-384.861368 172.782182-384.861368 385.182686 0 212.403574 172.647105 385.161197 384.861368 385.161197 212.224496 0 384.870578-172.782182 384.870578-385.161197C895.314641 297.196773 722.667535 124.414591 510.44304 124.414591z"
      p-id="10387"
    ></path>
  </svg>
);
export const errorSvg = (
  <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2339" width="15">
    <path
      d="M547.2 512l416-416c9.6-9.6 9.6-25.6 0-35.2s-25.6-9.6-35.2 0l-416 416-416-416c-9.6-9.6-25.6-9.6-35.2 0s-9.6 25.6 0 35.2l416 416-416 416c-9.6 9.6-9.6 25.6 0 35.2s25.6 9.6 35.2 0l416-416 416 416c9.6 9.6 25.6 9.6 35.2 0s9.6-25.6 0-35.2L547.2 512z"
      p-id="2340"
    ></path>
  </svg>
);

export const fmtNum = (value: number | string | undefined): string => {
  const intl = new Intl.NumberFormat("en-US", {});
  // value不为空时
  if (value !== undefined && value !== "") {
    return intl.format(parseFloat(value + ""));
  }
  return "";
};

// 卖速页面数字格式化显示及颜色相关共通方法
export const showNum = (num: number | string | null | undefined, typeFlag?: boolean) => {
  if (num === null || num === undefined || num === "") return "--";
  const val = typeof num === "number" ? num : parseFloat(num);
  if (isNaN(val)) return "--";
  return fmtNum(val + "") + (typeFlag ? "%" : "");
};
export const getRateColor = (num: string | null) => {
  if (num === null || num === undefined || num === "") return "black";
  const val = parseFloat(num);
  if (val > 100) {
    return "#0066EE";
  }
  if (val < 100) {
    return "red";
  }
  return "black";
};
// 排序
export const handleSort = (a: any, b: any) => {
  if (typeof a === "number" && typeof b === "number") {
    return a - b;
  } else {
    return String(a).localeCompare(String(b));
  }
};

export const fmtTime = (time?: dayjs.Dayjs | string | null, option?: { patten?: string; special?: boolean }) => {
  try {
    let { patten, special } = option || {};
    const curLang = getLanguageTypeInfo();
    if (!patten) {
      patten = curLang === "ja_JP" ? "YYYY-MM-DD" : "DD/MM/YYYY";
    }
    if (!time) return special ? (curLang === "ja_JP" ? "----/--/--" : "--/--/----") : "";
    return time instanceof dayjs ? time.utc().local().format(patten) : dayjs(time).utc().local().format(patten);
  } catch (error) {
    return "";
  }
};
