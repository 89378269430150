import { Dropdown, MenuProps } from "antd";
import langIcon from "@/assets/lang.svg";
import { getLanguageTypeInfo, setLanguageTypeInfo } from "@/utils/utils";
import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

export default (props: HTMLAttributes<any>) => {
  const languageInfo = getLanguageTypeInfo();
  const { t } = useTranslation();
  const items: MenuProps["items"] = [
    {
      key: "ja_JP",
      label: t("Common_language_japanese"),
      disabled: languageInfo === "ja_JP",
    },
    {
      key: "en_US",
      label: t("Common_language_english"),
      disabled: languageInfo === "en_US",
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    setLanguageTypeInfo(key);
    location.reload();
  };

  return (
    <Dropdown {...props} arrow={{ pointAtCenter: true }} placement="bottomRight" menu={{ items, onClick }}>
      <img src={langIcon} alt="" />
    </Dropdown>
  );
};
