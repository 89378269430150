import i18n from "@/Language/i18n";
import type { UploadFile } from "antd";
import { message, notification } from "antd";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import fileSaver from "file-saver";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import {
  HEADERS_ACCEPT_DOWNLOAD,
  MIMETYPE_FORMDATA,
  MIMETYPE_JSON,
  REQUEST_HEADER_AUTHORIZATION,
  REQUEST_HEADER_CONTENT_TYPE,
  REQUEST_HEADER_PAGINATION_INDEX,
  REQUEST_HEADER_PAGINATION_SIZE,
  REQUEST_HEADER_USER_LANG,
  RESPONSE_HEADER_CONTENT_DISPOSITION,
  RESPONSE_HEADER_CONTENT_TYPE,
  REQUEST_HEADER_GOOGLECODE
} from "./config";
import {
  getFormDataValue,
  getGoogleCode,
  getLanguageTypeInfo,
  getToken,
  removeToken,
} from "./utils";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  isDownload?: boolean;
  isFormData?: boolean;
}
// 接口返回类型定义
type PromiseData = {
  code?: number;
  message?: string;
  result?: [];
};

// 禁用进度条 Loading 效果
NProgress.configure({ showSpinner: false });

const serverCodeMessage: Record<number, string> = {
  200: i18n.t("The_server_successfully returned_the_requested_data"),
  400: i18n.t("Error_request"),
  401: i18n.t(
    "The_current_user_login_information_is_invalid_Please_log_in_again"
  ),
  403: i18n.t("No_Admittance"),
  404: i18n.t("not_found"),
  500: i18n.t("Server_error_Please_check_the_server"),
  502: i18n.t("Gateway_Error."),
  503: i18n.t(
    "Service_Unavailable_The_server_is_temporarily_overloaded_or_undergoing_maintenance"
  ),
  504: i18n.t("Gateway_Timeout."),
};
const customCodeMessage: Record<number, string> = {
  401: i18n.t(
    "The_current_user_login_information_is_invalid_Please_log_in_again"
  ),
};

// 对应接口报错信息静默处理
const silentErrorInterfaces = ["/system/login"];

const errorHandler = (error: any) => {
  NProgress.done();
  const { config, response, notifi } = error;
  // 下载报错特殊处理
  if (config.responseType === "blob") {
    const reader = new FileReader();
    reader.addEventListener("load", (e: ProgressEvent<FileReader>) => {
      const { code, message: msg } = JSON.parse(e.target?.result as string);
      if (code && code < 0) {
        message.error(msg);
      }
    });
    reader.readAsText(response.data);
    return;
  }
  if (notifi === "CustomError") {
    const { config, data } = response;
    const { url, baseURL } = config;
    const { code, msg } = data;
    const reqUrl = url.split("?")[0].replace(baseURL, "");
    const noVerifyBool = silentErrorInterfaces.includes(reqUrl);
    if (!noVerifyBool) {
      NProgress.done();
      notification.error({
        message: i18n.t("hint"),
        description: customCodeMessage[code] || msg || "Error",
      });
    }
  } else if (notifi === "CancelToken") {
    console.log(notifi);
  } else if (response && response.status) {
    const errorText = serverCodeMessage[response.status] || response.statusText;
    const { status /* request */ } = response;
    NProgress.done();
    notification.error({
      // message: `${i18n.t("Request_error")} ${status}: ${request.responseURL}`,
      message: `${i18n.t("Request_error")} ${status}`,
      description: errorText,
    });
    if (status === 401) {
      message.error(i18n.t("Common_message_tokenInvalid"));
      NProgress.done();
      removeToken();
      setTimeout(() => {
        window.location.href = "/user/login";
      }, 1000);
    }
  } else if (!response) {
    NProgress.done();
    notification.error({
      description: i18n.t(
        "Your_network_is_abnormal_and_cannot_connect_to_the_server"
      ),
      message: i18n.t("network_anomaly"),
    });
  }

  return Promise.reject(error);
};

const service = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 30000,
});

service.interceptors.request.use(
  (config: CustomAxiosRequestConfig): any => {
    NProgress.start();

    const accessToken = getToken();
    const googleCode = getGoogleCode();
    const isDownload = !!config.isDownload;
    const isFormData = !!config.isFormData;

    config.responseType = isDownload ? "blob" : "json";

    if (config.headers) {
      config.headers[REQUEST_HEADER_AUTHORIZATION] = accessToken
        ? `Bearer ${accessToken}`
        : "";
      config.headers[REQUEST_HEADER_GOOGLECODE] = googleCode ?? "";
      //config.headers["Accept"] = isDownload ? MIMETYPE_EXCEL : MIMETYPE_JSON;
      config.headers[REQUEST_HEADER_CONTENT_TYPE] = isFormData
        ? MIMETYPE_FORMDATA
        : MIMETYPE_JSON;
      config.headers[REQUEST_HEADER_USER_LANG] =
        getLanguageTypeInfo()?.replace("_", "-") ?? "ja-JP";
      if (config?.data && "pageIndex" in config.data) {
        config.headers[REQUEST_HEADER_PAGINATION_INDEX] = Math.max(
          config.data.pageIndex - 1,
          0
        );
        delete config.data.pageIndex;
      }
      if (config?.data && "pageSize" in config.data) {
        config.headers[REQUEST_HEADER_PAGINATION_SIZE] =
          config.data.pageSize || 10;
        delete config.data.pageSize;
      }
    }

    if (isFormData) {
      const formParam = new FormData();
      if (config.data) {
        if (config.data.files?.length) {
          config.data.files.forEach((file: UploadFile) => {
            formParam.append("file", file as any);
          });
          delete config.data.files;
        }
        if (config.data.imageFiles?.length) {
          config.data.imageFiles.forEach((file: UploadFile) => {
            formParam.append(
              "imageFile",
              file.originFileObj as File,
              file.name ?? ""
            );
          });
          delete config.data.imageFiles;
        }
        const keys = Object.keys(config.data) ?? [];
        keys?.forEach((key: string) => {
          formParam.append(key, getFormDataValue(config.data![key]));
        });
      }
      config.data = formParam;
    } else {
      config.data = JSON.stringify(config.data || {});
    }
    return config;
  },
  (error) => {
    errorHandler(error);
  }
);

const openErrorMessage = (code: number | string, message: string) => {
  notification.open({
    message: `Error ${code}`,
    description: message,
    type: "error",
  });
};
service.interceptors.response.use(
  (response: AxiosResponse) => {
    NProgress.done();
    const { data: configData, config, headers } = response;
    const { code, data, message: msg } = configData;
    if (Number(code) < 0) {
      message.error(msg);
      return;
    }
    if (config?.responseType === "blob") {
      if (
        HEADERS_ACCEPT_DOWNLOAD.includes(
          headers[RESPONSE_HEADER_CONTENT_TYPE] as string
        )
      ) {
        const content = headers[RESPONSE_HEADER_CONTENT_DISPOSITION];
        let downFileName = "";
        if (content) {
          const fileNameEncode = content.split("filename=")[1];
          downFileName = decodeURI(fileNameEncode);
        }
        fileSaver.saveAs(configData, downFileName);
      } else if (headers[RESPONSE_HEADER_CONTENT_TYPE] === MIMETYPE_JSON) {
        // 下载报错的特殊处理移到errorHandle处理
        const reader = new FileReader();
        reader.addEventListener("load", (e: ProgressEvent<FileReader>) => {
          const { code, message: msg } = JSON.parse(e.target?.result as string);
          if (code && code < 0) {
            message.error(msg);
            return;
          }
        });
        reader.readAsText(configData);
      }
    }
    return configData;
  },
  (error) => {
    errorHandler(error);
  }
);

type HttpRequest = {
  getUri(config?: CustomAxiosRequestConfig): string;
  get<T = PromiseData>(
    url: string,
    config?: CustomAxiosRequestConfig
  ): Promise<T>;
  delete<T = PromiseData>(
    url: string,
    config?: CustomAxiosRequestConfig
  ): Promise<T>;
  put<T = PromiseData>(
    url: string,
    data?: any,
    config?: CustomAxiosRequestConfig
  ): Promise<T>;
  post<T = PromiseData>(
    url: string,
    data?: any,
    config?: CustomAxiosRequestConfig
  ): Promise<T>;
  patch<T = PromiseData>(
    url: string,
    data?: any,
    config?: CustomAxiosRequestConfig
  ): Promise<T>;
};

const request: HttpRequest = {
  getUri: (config?: CustomAxiosRequestConfig) => service.getUri(config),
  get: (url: string, config?: CustomAxiosRequestConfig) =>
    service.get(url, config),
  delete: (url: string, config?: CustomAxiosRequestConfig) =>
    service.delete(url, config),
  put: (url: string, data?: any, config?: CustomAxiosRequestConfig) =>
    service.put(url, data, config),
  post: (url: string, data?: any, config?: CustomAxiosRequestConfig) =>
    service.post(url, data, config),
  patch: (url: string, data?: any, config?: CustomAxiosRequestConfig) =>
    service.patch(url, data, config),
};

export default request;
