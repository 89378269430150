import i18n from "@/Language/i18n";
import { lazyWithErrorHandle } from "@/utils/utils";

export default {
  path: "overseasSalesSpeed",
  meta: {
    title: i18n.t("OverseasSalesSpeed_fether_title"),
    roles: ["overseasSalesSpeed"],
  },
  redirect: "overseasSalesSpeed-totalSalesSpeed",
  children: [
    // 売速集計
    {
      path: "overseasSalesSpeed-totalSalesSpeed",
      meta: {
        title: i18n.t("OverseasSalesSpeed_Total_sales_speed"),
        roles: ["overseasSalesSpeed-totalSalesSpeed"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/TotalSalesSpeed")),
    },
    // 各国贩速一览
    {
      path: "overseasSalesSpeed-countryAllSellingSpeed",
      meta: {
        title:
          JSON.parse(sessionStorage.getItem("userInfo")!)?.countryCD === "00"
            ? i18n.t("OverseasSalesSpeed_Country_All_Selling_Speed")
            : i18n.t("OverseasSalesSpeed_Country_All_Selling_Speed_not_Japan"),
        roles: ["overseasSalesSpeed-countryAllSellingSpeed"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/CountryAllSellingSpeed")),
    },
    // 売速ファイルアップロード
    {
      path: "overseasSalesSpeed-UploadExpressFile",
      meta: {
        title: i18n.t("OverseasSalesSpeed_Upload_the_Express_file"),
        roles: ["overseasSalesSpeed-UploadExpressFile"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/UploadExpressFile")),
    },
  ],
};
