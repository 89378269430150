/**
 * 接口 API
 * @description [ 请求类型 ， 是否需要拼接 ID 或其它调用 ]
 * @demonstration [ 请求类型 ， getCurrentUserInfo/1 ( join ) ]
 */

export default {
  // --------------------------------------------------------

  system_login: "/system/login", //? 登录 [ POST ]

  system_loginEdit: "/system/loginEdit", //? 编辑model登录

  system_logout: "/system/logout", //? 退出 [ POST ]

  // system_currentPageAuthority: "/system/currentPageAuthority", //? 获取当前用户菜单 [ GET （ JOIN ）]

  system_selectMenu: "/system/selectMenu", //? 所有页面 tree 树结构

  system_currentUser: "/system/currentUser", //? 获取当前用户信息 [ GET ]

  system_currentMenu: "/system/currentMenu", //? 获取用户信息及路由 [ GET ]

  system_passwordChange: "/system/passwordChange", //? 主动密码change [ POST ]

  system_forgetPassword: "/system/forgetPassword", //? 忘记密码 [ POST ]

  system_forgetPasswordJapan: "/system/forgetPasswordJapan", //? 忘记密码 [ POST ]

  system_sendVerifyEmail: "/system/sendVerifyEmail", //? 发送验证邮件 [ POST ]

  system_emailLogin: "/system/emailLogin", // ? 邮箱验证登录 [ POST ]

  system_getRedirectUrl: "/system/getRedirectUrl", // ? 获取重定向地址 [ POST ]

  api_system_saveLog: "/system/saveLog", // 登录报错日志

  user_findTenantId: "/user/findTenantId", // 获取TenantId

  user_fetchUser: "/user/fetchUser", //? 用户一览检索 [ POST ]

  user_fetchCountry: "/user/fetchCountry", //? 获取国家列表 [ POST ]

  user_page_fetchCountry: "/user/findCountry", //? 获取国家列表 [ POST ]

  code_fetchDistinctionList: "/code/fetchDistinctionList", //? コードマスタ 区分检索 [ POST ]

  code_fetchCodeList: "/code/fetchCodeList", //? コードマスタ 一览检索 [ POST ]

  code_fetchCodes: "/code/fetchCodes", //? コードマスタ 下拉检索 [ POST ]

  code_addCodeBook: "/code/addCodeBook", //? コードマスタ 新增 [ POST ]

  code_editCodeList: "/code/editCodeList", //? コードマスタ 编辑 [ POST ]

  code_deleteCodeBook: "/code/deleteCodeBook", //? コードマスタ 删除 [ DELETE （ JOIN ）]

  user_sendMailTest: "/user/sendMailTest", //? ユーザ一覧画面 发送邮件 [ POST ]

  user_editUser: "/user/editUser", //? ユーザ一覧画面 新增编辑 [ POST ]

  user_deleteUser: "/user/deleteUser", //? ユーザ一覧画面 删除 [ DELETE （ JOIN ）]

  role_fetchRoles: "/role/fetchRoles", //? ロール一覧画面 角色下拉检索 [ POST ]

  role_searchRoles: "/role/searchRoles", //? ロール一覧画面 角色一览检索 [ POST ]

  role_editRole: "/role/editRole", //? ロール一覧画面 新增编辑 [ POST ]

  role_deleteRole: "/role/deleteRole", //? ロール一覧画面 删除 [ DELETE （ JOIN ）]

  role_findByRoleId: "/role/findByRoleId", //? ロール一覧画面 查询当前角色菜单信息 [ GET ( JOIN ) ]

  store_fetchStoreList: "/store/fetchStoreList", //? 各国店舗一覧 店铺一览检索 [ POST ]

  store_exportStoreInfo: "/store/exportStoreInfo", //? 各国店舗一覧 导出 [ POST ]

  store_editStoreList: "/store/editStoreList", //? 各国店舗一覧 新增编辑 [ POST ]

  store_complete: "/store/complete", //? 各国店舗一覧 完了 [ POST ]

  store_storeCount: "/store/storeCount", //? 掲載用店舗数画面  查询店铺数  [ POST ]

  store_save_Temporary_Cnt: "/store/saveTemporaryCnt", //? 掲載用店舗数画面  修改日本行  [ POST ]

  store_aggregationGraph: "/store/aggregationGraph", //? 统计图表

  //! 各国店铺一览单个删除接口 已废弃
  store_deleteStore: "/store/deleteStore", //? 各国店舗一覧 删除 [ DELETE (JOIN) ]

  notice_noticeDaySendMail: "/notice/noticeDaySendMail", // ? 周知日送信 [ POST ]

  notice_modifyNoticeDay: "/notice/modifyNoticeDay", // ? 周知日编辑 [ POST ]

  notice_fetchNoticeDay: "/notice/fetchNoticeDay", // ? 周知日检索 [ POST ]

  concept_fetchConceptList: "/concept/fetchConceptList", //? 屋号管理一览检索

  concept_editConcept: "/concept/editConcept", //? 屋号编辑

  developer_fetchDeveloperList: "/developer/fetchDeveloperList", //? 店铺一览 【开发商一览检索 => 用于 ディベロッパー名  内外联动】 [ POST ]

  developer_editDeveloper: "/developer/editDeveloper", //? 开发商编辑提交 [ POST ]

  developer_fetchDeveloperById: "/developer/fetchDeveloperById", //? 开发商详情 [ GET ]

  developer_deleteById: "/developer/deleteById", //? 开发商删除 [ DELETE ]

  store_getLatestUpdateTime: "/store/getLatestUpdateTime", //? 店铺数集记 获取最新更新时间 [ POST ]

  area_fetchAreas: "/area/fetchAreas", //? 地区一览检索

  area_deleteById: "/area/deleteById", //? 地区删除 [ DELETE ]

  area_editArea: "/area/editArea", //? 地区新增 编辑

  sale_fetchSales: "/sale/fetchSales", //? 各国卖速一览

  sale_getAssignDate: "/sale/getAssignDate", // ? 卖速一览 计上日期

  sale_fetchSaleTotal: "/sale/fetchSaleTotal", //? 卖速一览 卖速集计

  sale_importSales: "/sale/importSales", //? 売速ファイルアップロード

  store_fetchTemporaryCnt: "/store/fetchTemporaryCnt", //? 一时区画检索

  rate_fetchRateCodeList: "/rate/fetchRateCodeList",

  rate_fetchRates: "/rate/fetchRates", //? 汇率检索

  rate_editRate: "/rate/editRate", //? 汇率新增 编辑

  fileManage_manualFileUpload: "/fileManage/manualFileUpload", //文件file
  fileManage_manualFileDelete: "/fileManage/manualFileDelete", //条件：id
  fileManage_manualFileDownload: "/fileManage/manualFileDownload", //条件：id
  fileManage_fetchManualInfo: "/fileManage/fetchManualInfo", //无条件
  fileManage_setManualInfoOrder: "/fileManage/setManualInfoOrder", // 文件列表：mlist
};
