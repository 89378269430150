import { lazyWithErrorHandle } from "@/utils/utils";
import type { RouterProps } from "../../../router/index";

const UserLayoutRoutes: RouterProps[] = [
  {
    path: `/user/login`,
    component: lazyWithErrorHandle(() => import("@/pages/Login")),
  },
  {
    path: `/user/googleLogin`,
    component: lazyWithErrorHandle(() => import("@/pages/LoginWithin")),
  },
];

export default UserLayoutRoutes;
